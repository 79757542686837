/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable prettier/prettier */

/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable no-debugger */

/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
import React, { useState, useEffect,useContext } from "react";
import moment from "moment";
import {
  RACButton,
  Grid,
  RACModalCard,
  RACTextbox,
  RACDatePicker,
  Modal,
  CircularProgress,
  Typography,
} from "@rentacenter/racstrap";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { getTax } from "../../api/user";
import AdjustDueDatecalci from "../../racstrapComponents/microfrontends/AdjustDueDateCalci";
import calculationModulePayloadFormation from "./commonModulePayloadFormation";
import { calculatePaymentModule } from "../../api/user";
import { userTraceContextData } from "../../context/UserTraceContext";
const AdjustDueDatePopup = (props: any) => {
  const {
    userTraceValue,
  } = useContext(userTraceContextData);
  const [userTraceData, setUserTraceData] = userTraceValue;
  const classes = takePaymentStyles();
  const MyDate = new Date(props.adjustDueDateReq[0].CurrentDueDate);
  const currentduedate =
    MyDate.getFullYear() +
    "-" +
    ("0" + (MyDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + MyDate.getDate()).slice(-2);
  const [loadSpinner, setloadSpinner] = useState<boolean>(false); //used to load the spinner and dibale the both amount text box and next due date text box
  const res = Number(
    Number(
      props.adjustDueDateReq
        .map((bill) => bill.Amount)
        .reduce((acc, bill) => (Number(bill) + Number(acc)).toFixed(2))
    ).toFixed(2)
  ); //get the addition of the amount of all agrrement in the adjust all due date case
  const [totalAmount, settotalAmount] = useState<number>(res); //needs to set the totalAmount according to the response of AdjustDueDate service
  const [nextDueDate, setnextDueDate] = useState<any>(""); //needs to set according to the response from AjustDueDate Service
  const [errorAmount, seterrorAmount] = useState<boolean>(false); // used for the error msg if the date is invalid
  let [errorValue, seterrorValue] = useState<any>(0);
  const [resAmount, setresAmount] = useState<any>(res); // used to not to trigger the fe lambda if the values are same than the previous execution
  const [calciRes, setcalciRes] = useState<any>(res);
  const [finalAdjustedArray, setfinalAdjustedArray] = useState<any>([]);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [isInvalidDDSelected, setIsInvalidDDSelected] = useState<boolean>(false);

  useEffect(() => {
    console.log("props in adjustDueDate", props);
  }, []);

  //on change of next due date && totalAmountDue adjustDueDate
  const dateFormate = (input: any) => {
    return moment(input).format("MM/DD/YYYY");
  };
  let adjustDueRes: any = [];
  const amountOnChange = (e: any) => {
    setfinalAdjustedArray([]);
    if (isNaN(e.target.value) == false) {
      let Amount = e.target.value;
      settotalAmount(Amount);
      const extrabool = String(Amount).includes(".")
        ? String(Amount)
            .split(".")[1]
            .match(/.{1,1}/g)?.length
        : 0;
      if (Number(extrabool) > 2) {
        Amount = totalAmount;
        settotalAmount(totalAmount);
      }
      // setcarryRentTextValue(Amount)
      debugger;
      settotalAmount(Amount);
      if (Amount == undefined) {
        settotalAmount(0.0);
      }
      let validationTotalAmount = 0;

      for (let i = 0; i < props.adjustDueDateReq.length; i++) {
        let ldwTerminateToggle = 0;
        let coverageEndDate = "";
        // let policyStatus=""
        let coverageEndDateInfo: any = [];
        if (props.ldwChangeArr.length > 0) {
          // props.ldwChangeArr
          coverageEndDateInfo = props.ldwChangeArr.filter(
            (x) =>
              String(x.agreementId) ===
              String(props.adjustDueDateReq[i].Agreementid)
          );
          if (coverageEndDateInfo.length > 0) {
            if (Number(coverageEndDateInfo[0].action) == 2) {
              const currentdate: Date = new Date(
                new Date().toLocaleString("en-US", {
                  timeZone: "America/Chicago",
                })
              );
              const current_date = new Date(
                currentdate.getFullYear(),
                currentdate.getMonth(),
                currentdate.getDate()
              );
              coverageEndDate = moment(current_date).format("MM/DD/YYYY");
              ldwTerminateToggle = 1;
            } else if (Number(coverageEndDateInfo[0].action) == 1) {
              coverageEndDate = "12/31/2099";
              ldwTerminateToggle = 2;
              if (
                Number(
                  props.adjustDueDateReq[i].agreementRateDetails.perDayLdw
                ) == 0
              ) {
                props.adjustDueDateReq[i].agreementRateDetails.perDayLdw = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ) / 7
                ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.perDayLdwTax = (
                  (Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ) /
                    7) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.weeklyLdw =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.weeklyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.biWeeklyLdw =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .biweeklyRate
                  ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.biWeeklyLdwTax =
                  (
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo
                        .scheduleRates.biweeklyRate
                    ) *
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                    )
                  ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.monthlyLdw =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .monthlyRate
                  ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.monthlyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .monthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.semiMonthlyLdw =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .semimonthlyRate
                  ).toFixed(2);
                props.adjustDueDateReq[
                  i
                ].agreementRateDetails.semiMonthlyLdwTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .semimonthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
              }
            }
          }
        }
        if (coverageEndDate == "") {
          coverageEndDate = String(props.adjustDueDateReq[i].coverageEndDate);
        }
        let ldwtax = 0;
        let ldwAmount = 0;
        let ldwtaxwithAmount = 0;
        if (props.adjustDueDateReq[i].Agreementtype != "Club") {
          if (
            Number(props.adjustDueDateReq[i].agreementRateDetails.perDayLdw) ==
              0 &&
            ldwTerminateToggle == 2
          ) {
            if (
              Number(
                coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                  .weeklyRate
              ) > 0
            ) {
              ldwtaxwithAmount = Number(
                (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .weeklyRate
                  ) /
                    7 +
                  (Number(
                    coverageEndDateInfo[0].ldwInfo.scheduleRates.weeklyRate
                  ) /
                    7) *
                    Number(coverageEndDateInfo[0].ldwInfo.ldwTaxRate)
                ).toFixed(2)
              );
            }
          } else {
            ldwtax =
              ldwTerminateToggle == 1 ||
              Number(props.adjustDueDateReq[i].agrLdwAmount) == 0
                ? 0
                : Number(
                    props.adjustDueDateReq[i].agreementRateDetails.perDayLdwTax
                  );
            ldwAmount =
              ldwTerminateToggle == 1 ||
              Number(props.adjustDueDateReq[i].agrLdwAmount) == 0
                ? 0
                : Number(
                    props.adjustDueDateReq[i].agreementRateDetails.perDayLdw
                  );
            ldwtaxwithAmount = Number(ldwtax) + Number(ldwAmount);
          }
        }
        //policy
        let policyToggle = 0;
        if (props.policyChangeArr.length > 0) {
          // props.ldwChangeArr
          const policyChangeArrInfo = props.policyChangeArr.filter(
            (x) =>
              String(x.agreementId) ===
              String(props.adjustDueDateReq[i].Agreementid)
          );
          if (policyChangeArrInfo.length > 0) {
            if (Number(policyChangeArrInfo[0].action) == 2) {
              policyToggle = 1;
              // policyStatus = "I"
            } else if (Number(policyChangeArrInfo[0].action) == 1) {
              policyToggle = 2;
              if (
                Number(
                  props.adjustDueDateReq[i].agreementRateDetails.perDayPolicy
                ) == 0
              ) {
                props.adjustDueDateReq[i].agreementRateDetails.perDayPolicy = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ) / 7
                ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.perDayPolicyTax =
                  (
                    (Number(
                      coverageEndDateInfo[0].cusCoverageData.policyInfo
                        .scheduleRates.weeklyRate
                    ) /
                      7) *
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.policyInfo
                        .ldwTaxRate
                    )
                  ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.weeklyPolicy =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.weeklyPolicyTax =
                  (
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.policyInfo
                        .scheduleRates.weeklyRate
                    ) *
                    Number(
                      coverageEndDateInfo[0].cusCoverageData.policyInfo
                        .ldwTaxRate
                    )
                  ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.biWeeklyPolicy =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.biweeklyRate
                  ).toFixed(2);
                props.adjustDueDateReq[
                  i
                ].agreementRateDetails.biWeeklyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.biweeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.adjustDueDateReq[i].agreementRateDetails.monthlyPolicy =
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.monthlyRate
                  ).toFixed(2);
                props.adjustDueDateReq[
                  i
                ].agreementRateDetails.monthlyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.monthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
                props.adjustDueDateReq[
                  i
                ].agreementRateDetails.semiMonthlyPolicy = Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.semimonthlyRate
                ).toFixed(2);
                props.adjustDueDateReq[
                  i
                ].agreementRateDetails.semiMonthlyPolicyTax = (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.semimonthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
              }
              // policyStatus = "A"
            }
          }
        }
        // let
        let policyAmount = 0;
        if (props.adjustDueDateReq[i].Agreementtype != "Club") {
          if (
            Number(
              props.adjustDueDateReq[i].agreementRateDetails.perDayPolicy
            ) == 0 &&
            policyToggle == 2
          ) {
            if (
              Number(
                coverageEndDateInfo[0].cusCoverageData.policyInfo.scheduleRates
                  .weeklyRate
              ) > 0
            ) {
              policyAmount = Number(
                (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.weeklyRate
                  ) /
                    7 +
                  (Number(
                    coverageEndDateInfo[0].policyInfo.scheduleRates.weeklyRate
                  ) /
                    7) *
                    Number(coverageEndDateInfo[0].policyInfo.ldwTaxRate)
                ).toFixed(2)
              );
            }
          } else {
            // ldwtax = policyToggle==1?0:Number(props.adjustDueDateReq[i].agreementRateDetails.perDayPolicyTax)
            // ldwAmount = policyToggle==1?0:Number(props.adjustDueDateReq[i].agreementRateDetails.perDayPolicy)
            policyAmount =
              Number(
                policyToggle == 1 ||
                  Number(props.adjustDueDateReq[i].policyAmount) == 0
                  ? 0
                  : Number(
                      props.adjustDueDateReq[i].agreementRateDetails
                        .perDayPolicyTax
                    )
              ) +
              Number(
                policyToggle == 1
                  ? 0
                  : Number(
                      props.adjustDueDateReq[i].agreementRateDetails
                        .perDayPolicy
                    )
              );
          }
        }

        //  if(Number((Number(props.adjustDueDateReq[i].agreementRateDetails.perDayPolicy)+Number(props.adjustDueDateReq[i].agreementRateDetails.perDayPolicyTax)).toFixed(2))>0){
        //   policyAmount=Number(props.adjustDueDateReq[i].agreementRateDetails.perDayPolicy)+Number(props.adjustDueDateReq[i].agreementRateDetails.perDayPolicyTax)
        //  }
        validationTotalAmount =
          validationTotalAmount +
          Number(props.adjustDueDateReq[i].agreementRateDetails.perDayRent) +
          Number(props.adjustDueDateReq[i].agreementRateDetails.perDayRentTax) +
          Number(ldwtaxwithAmount) +
          Number(
            props.adjustDueDateReq[i].lFeeTaxAmount == undefined
              ? "0.00"
              : props.adjustDueDateReq[i].lFeeTaxAmount
          ) +
          Number(
            props.adjustDueDateReq[i].lateFee == undefined
              ? "0.00"
              : props.adjustDueDateReq[i].lateFee
          ) +
          Number(policyAmount) +
          Number(props.adjustDueDateReq[i].agreementRateDetails.carryRent) +
          Number(props.adjustDueDateReq[i].agreementRateDetails.carryRentTax);

        //  let ldwtax = ldwTerminateToggle==1?0:Number(props.adjustDueDateReq[i].agreementRateDetails.perDayLdwTax)
        //  let ldwAmount = ldwTerminateToggle==1?0:Number(props.adjustDueDateReq[i].agreementRateDetails.perDayLdw)
      }
      errorValue=Number(validationTotalAmount)
      if (Number(Amount) < Number(validationTotalAmount)) {
        seterrorAmount(true);
        errorValue=Number(validationTotalAmount)
        seterrorValue(Number(validationTotalAmount));
        // seterrorValue()
      } else {
        seterrorAmount(false);
        seterrorValue(0);
      }
    } else {
      settotalAmount(totalAmount);
    }
  };
  const adjustDueDateChange = async (e: any, ref: string) => {
    debugger;

    // setloadSpinner(true)
    // if(props)
    let coverageEndDateInfo: any = [];
    const adjustDueDatereq: any = [];
    setfinalAdjustedArray([]);
    for (let i = 0; i < props.adjustDueDateReq.length; i++) {
      let coverageEndDate = "";
      let policyStatus = "";
      if (props.ldwChangeArr.length > 0) {
        // props.ldwChangeArr
        coverageEndDateInfo = props.ldwChangeArr.filter(
          (x) =>
            String(x.agreementId) ===
            String(props.adjustDueDateReq[i].Agreementid)
        );
        if (coverageEndDateInfo.length > 0) {
          if (Number(coverageEndDateInfo[0].action) == 2) {
            coverageEndDate = moment().format("MM/DD/YYYY");
          } else if (Number(coverageEndDateInfo[0].action) == 1) {
            coverageEndDate = "12/31/2099";
            if (
              Number(
                props.adjustDueDateReq[i].agreementRateDetails.perDayLdw
              ) == 0
            ) {
              props.adjustDueDateReq[i].agreementRateDetails.perDayLdw = (
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .weeklyRate
                ) / 7
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.perDayLdwTax = (
                (Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .weeklyRate
                ) /
                  7) *
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                )
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.weeklyLdw = Number(
                coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                  .weeklyRate
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.weeklyLdwTax = (
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .weeklyRate
                ) *
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                )
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.biWeeklyLdw =
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .biweeklyRate
                ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.biWeeklyLdwTax = (
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .biweeklyRate
                ) *
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                )
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.monthlyLdw =
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .monthlyRate
                ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.monthlyLdwTax = (
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .monthlyRate
                ) *
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                )
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.semiMonthlyLdw =
                Number(
                  coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                    .semimonthlyRate
                ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.semiMonthlyLdwTax =
                (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.scheduleRates
                      .semimonthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.ldwInfo.ldwTaxRate
                  )
                ).toFixed(2);
            }
          }
        }
      }
      if (coverageEndDate == "") {
        coverageEndDate = String(props.adjustDueDateReq[i].coverageEndDate);
      }
      //policy
      if (props.policyChangeArr.length > 0) {
        // props.ldwChangeArr
        const policyChangeArrInfo = props.policyChangeArr.filter(
          (x) =>
            String(x.agreementId) ===
            String(props.adjustDueDateReq[i].Agreementid)
        );
        if (policyChangeArrInfo.length > 0) {
          if (Number(policyChangeArrInfo[0].action) == 2) {
            policyStatus = "I";
          } else if (Number(policyChangeArrInfo[0].action) == 1) {
            policyStatus = "A";
            if (
              Number(
                props.adjustDueDateReq[i].agreementRateDetails.perDayPolicy
              ) == 0
            ) {
              props.adjustDueDateReq[i].agreementRateDetails.perDayPolicy = (
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.weeklyRate
                ) / 7
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.perDayPolicyTax = (
                (Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.weeklyRate
                ) /
                  7) *
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                )
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.weeklyPolicy =
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.weeklyRate
                ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.weeklyPolicyTax = (
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.weeklyRate
                ) *
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                )
              ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.biWeeklyPolicy =
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.biweeklyRate
                ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.biWeeklyPolicyTax =
                (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.biweeklyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.monthlyPolicy =
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.monthlyRate
                ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.monthlyPolicyTax =
                (
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo
                      .scheduleRates.monthlyRate
                  ) *
                  Number(
                    coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                  )
                ).toFixed(2);
              props.adjustDueDateReq[i].agreementRateDetails.semiMonthlyPolicy =
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.semimonthlyRate
                ).toFixed(2);
              props.adjustDueDateReq[
                i
              ].agreementRateDetails.semiMonthlyPolicyTax = (
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo
                    .scheduleRates.semimonthlyRate
                ) *
                Number(
                  coverageEndDateInfo[0].cusCoverageData.policyInfo.ldwTaxRate
                )
              ).toFixed(2);
            }
          }
        }
      }
      if (policyStatus == "") {
        policyStatus = String(props.adjustDueDateReq[i].policyStatus);
      }
      let scheduleRef = "";
      if (
        props.adjustDueDateReq[i].editSchedule != "" &&
        props.adjustDueDateReq[i].editSchedule != null
      ) {
        scheduleRef = props.adjustDueDateReq[i].editSchedule;
      } else {
        scheduleRef =
          props.adjustDueDateReq[i].agreementRateDetails.scheduleRefCode;
      }
      const res = {
        Agreementtype: props.adjustDueDateReq[i].Agreementtype,
        agreementId: String(props.adjustDueDateReq[i].Agreementid),
        agreementRateDetails: props.adjustDueDateReq[i].agreementRateDetails,
        currentDueDate: String(props.adjustDueDateReq[i].CurrentDueDate),
        dueDate: String(props.adjustDueDateReq[i].Nextduedate),
        coverageEndDate: coverageEndDate,
        taxRate: String(props.adjustDueDateReq[i].taxRate),
        carriedLate: String(props.adjustDueDateReq[i].carriedLate),
        carriedLateTax: String(props.adjustDueDateReq[i].carriedLateTax),
        lateFee: String(props.adjustDueDateReq[i].lateFee),
        lateFeeTax: String(props.adjustDueDateReq[i].lFeeTaxAmount),
        policyStatus: String(policyStatus),
        epoAmount: String(props.adjustDueDateReq[i].EPOAmount),
        epoTax: String(props.adjustDueDateReq[i].EPOTax),
        sacDate: String(props.adjustDueDateReq[i].SAC_Date),
        isLdwAllowed: String(props.adjustDueDateReq[i].isLdwAllowed),
        agreementNumber: String(props.adjustDueDateReq[i].AgreementNumber),
        carryRent: String(props.adjustDueDateReq[i].carryRentAmountUsed),
        carryRentTax: String(props.adjustDueDateReq[i].carryRentTaxUsed),
        scheduleRefCode: scheduleRef,
        ldwPercentage: props.adjustDueDateReq[i].ldwPercentage
          ? String(props.adjustDueDateReq[i].ldwPercentage)
          : undefined,
        agreementStatus: props.adjustDueDateReq[i].agreementStatus
          ? String(props.adjustDueDateReq[i].agreementStatus)
          : undefined,
        agreementPromotionInfo:
          props.adjustDueDateReq[i].promotionType !== undefined &&
          props.adjustDueDateReq[i].promotionType !== null
            ? {
                agreementId: String(props.adjustDueDateReq[i].Agreementid),
                promotionRate:
                  props.adjustDueDateReq[i].promotionRate != null &&
                  props.adjustDueDateReq[i].promotionRate !== "" &&
                  props.adjustDueDateReq[i].promotionRate
                    ? String(props.adjustDueDateReq[i].promotionRate)
                    : undefined,
                promotionType:
                  props.adjustDueDateReq[i].promotionType &&
                  props.adjustDueDateReq[i].promotionType !== null &&
                  props.adjustDueDateReq[i].promotionType !== ""
                    ? String(props.adjustDueDateReq[i].promotionType)
                    : undefined,
                promotionDueDate:
                  props.adjustDueDateReq[i].promotionDueDate &&
                  props.adjustDueDateReq[i].promotionDueDate !== null &&
                  props.adjustDueDateReq[i].promotionDueDate !== ""
                    ? String(props.adjustDueDateReq[i].promotionDueDate)
                    : undefined,
                maxWeeksAllowed:
                  props.adjustDueDateReq[i].maxWeeksAllowed &&
                  props.adjustDueDateReq[i].maxWeeksAllowed !== null &&
                  props.adjustDueDateReq[i].maxWeeksAllowed !== ""
                    ? String(props.adjustDueDateReq[i].maxWeeksAllowed)
                    : undefined,
                freeTimeWeeksAllowed:
                  props.adjustDueDateReq[i].freeTimeWeeksAllowed &&
                  props.adjustDueDateReq[i].freeTimeWeeksAllowed !== null &&
                  props.adjustDueDateReq[i].freeTimeWeeksAllowed !== ""
                    ? String(props.adjustDueDateReq[i].freeTimeWeeksAllowed)
                    : undefined,
                numberOfFreeDays:
                  props.adjustDueDateReq[i].numberOfFreeDays &&
                  props.adjustDueDateReq[i].numberOfFreeDays !== null &&
                  props.adjustDueDateReq[i].numberOfFreeDays !== ""
                    ? String(props.adjustDueDateReq[i].numberOfFreeDays)
                    : undefined,
                referralPromoFreeDays:
                  props.adjustDueDateReq[i].referralPromoFreeDays &&
                  props.adjustDueDateReq[i].referralPromoFreeDays !== null &&
                  props.adjustDueDateReq[i].referralPromoFreeDays !== ""
                    ? String(props.adjustDueDateReq[i].referralPromoFreeDays)
                    : undefined,
                promotionDetails:
                  props.adjustDueDateReq[i].promotionDetails &&
                  props.adjustDueDateReq[i].promotionDetails !== null &&
                  props.adjustDueDateReq[i].promotionDetails !== ""
                    ? String(props.adjustDueDateReq[i].promotionDetails)
                    : undefined,
              }
            : undefined,
      };
      adjustDueDatereq.push(res);
    }
    if (ref == "Date") {
      seterrorAmount(false);
      if (new Date(e) < new Date(props.adjustDueDateReq[0].CurrentDueDate)) {
        // seterrorDate(true)
      } else if (dateFormate(e) == dateFormate(nextDueDate)) {
        // seterrorDate(false)
      } else {
        // seterrorDate(false)

        const calcireq = {
          rateResponse: adjustDueDatereq,
          dueDate: dateFormate(e),
        };
        //   adjustDueDatereq ={
        //   "agreementId":["1","1"],
        //   "currentDueDate":props.adjustDueDateReq[0].CurrentDueDate,
        //   "nextDueDate":"",
        //   "totalAmount":String(totalAmount)

        // }
        debugger;
        console.log("calcireq in adj cal comp0", calcireq);

        adjustDueRes = await AdjustDueDatecalci(calcireq);
        debugger;
        const finadjust: any = [];
        let totalAmount = 0;
        let totalTaxAmount = 0;
        for (let index = 0; index < adjustDueRes.length; index++) {
          totalAmount = totalAmount + Number(adjustDueRes[index].totalAmount) + Number(adjustDueRes[index].carriedLate);
          totalTaxAmount = totalTaxAmount + Number(adjustDueRes[index].totalTax) + Number(adjustDueRes[index].carriedLateTax);
          if (
            adjustDueRes[index].nextDueDate != "SAC" &&
            adjustDueRes[index].nextDueDate != "PIF" &&
            adjustDueRes[0].nextDueDate != "EPO"
          ) {
            const MyDate = new Date(adjustDueRes[0].nextDueDate);
            const nextDueDateFormate =
              MyDate.getFullYear() +
              "-" +
              ("0" + (MyDate.getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + MyDate.getDate()).slice(-2);
            setnextDueDate(nextDueDateFormate);
          } else {
            finadjust.push(
              `Adjusted AgreementNumber # ${adjustDueRes[index].agreementNumber} - will be ${adjustDueRes[index].nextDueDate} `
            );
          }
        }
        setfinalAdjustedArray(finadjust);
        setcalciRes(adjustDueRes);
        if (props.adjustDueDateReq?.length==1&& props.adjustDueDateReq[0].agreementStatus == "PRELIMINV") {
          let otherFeeWithTax = Number(props.adjustDueDateReq[0].processingFee )+
          Number( props.adjustDueDateReq[0].deliveryAmount )+
          Number( props.adjustDueDateReq[0].mattressFee) +
          Number( props.adjustDueDateReq[0].processingFeeTax )+
          Number( props.adjustDueDateReq[0].deliveryAmountTax )+
          Number( props.adjustDueDateReq[0].mattressFeeTax);
          let otherFeeTax = 
          Number( props.adjustDueDateReq[0].processingFeeTax )+
          Number( props.adjustDueDateReq[0].deliveryAmountTax )+
          Number( props.adjustDueDateReq[0].mattressFeeTax);

          otherFeeWithTax =Number(otherFeeWithTax)>0?Number(otherFeeWithTax):0;
          otherFeeTax=Number(otherFeeTax)>0?Number(otherFeeTax):0;
          settotalAmount(Number((totalAmount + totalTaxAmount+otherFeeWithTax).toFixed(2)));
        setresAmount(Number((totalAmount + totalTaxAmount +otherFeeWithTax).toFixed(2)));
        }else{
          settotalAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
          setresAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
        }
        
      }
      setnextDueDate(e);
    } else {
      if(props.adjustDueDateReq?.length==1&& props.adjustDueDateReq[0].agreementStatus == "PRELIMINV"&&Number(e.target.value)<=Number(errorValue)) {
        adjustDueDateChange(moment(currentduedate).add(1, "d").format("YYYY-MM-DD"),"Date")
      }else
      if (errorAmount == false) {
        if (Number(e.target.value) != Number(resAmount)) {
          //   const adjustDueDatereq ={
          //     "agreementId":["1","1"],
          //     "currentDueDate":props.adjustDueDateReq[0].CurrentDueDate,
          //     "nextDueDate":"",
          //     "totalAmount":String(totalAmount)

          // }
          setfinalAdjustedArray([]);
          const calcireq = {
            rateResponse: adjustDueDatereq,
            amountAdjusted: String(e.target.value),
          };
          debugger;
          adjustDueRes = await AdjustDueDatecalci(calcireq);
          debugger;

          const finadjust: any = [];
          let totalAmount = 0;
          let totalTaxAmount = 0;
          for (let index = 0; index < adjustDueRes.length; index++) {
            totalAmount = totalAmount + Number(adjustDueRes[index].totalAmount) + Number(adjustDueRes[index].carriedLate);
            totalTaxAmount = totalTaxAmount + Number(adjustDueRes[index].totalTax) + Number(adjustDueRes[index].carriedLateTax);
            if (
              adjustDueRes[index].nextDueDate != "SAC" &&
              adjustDueRes[index].nextDueDate != "PIF" &&
              adjustDueRes[0].nextDueDate != "EPO"
            ) {
              const MyDate = new Date(adjustDueRes[0].nextDueDate);
              const nextDueDateFormate =
                MyDate.getFullYear() +
                "-" +
                ("0" + (MyDate.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + MyDate.getDate()).slice(-2);
              setnextDueDate(nextDueDateFormate);
            } else {
              finadjust.push(
                `Adjusted AgreementNumber # ${adjustDueRes[index].agreementNumber} - will be ${adjustDueRes[index].nextDueDate} `
              );
            }
          }
          setfinalAdjustedArray(finadjust);
          setcalciRes(adjustDueRes);
          if (props.adjustDueDateReq?.length==1&& props.adjustDueDateReq[0].agreementStatus == "PRELIMINV"&&Number(e.target.value)>Number(errorValue)) {
            let otherFeeWithTax = Number(props.adjustDueDateReq[0].processingFee )+
            Number( props.adjustDueDateReq[0].deliveryAmount )+
            Number( props.adjustDueDateReq[0].mattressFee) +
            Number( props.adjustDueDateReq[0].processingFeeTax )+
            Number( props.adjustDueDateReq[0].deliveryAmountTax )+
            Number( props.adjustDueDateReq[0].mattressFeeTax);
            let otherFeeTax = 
            Number( props.adjustDueDateReq[0].processingFeeTax )+
            Number( props.adjustDueDateReq[0].deliveryAmountTax )+
            Number( props.adjustDueDateReq[0].mattressFeeTax);
  
            otherFeeWithTax =Number(otherFeeWithTax)>0?Number(otherFeeWithTax):0;
            otherFeeTax=Number(otherFeeTax)>0?Number(otherFeeTax):0;
            settotalAmount(Number((totalAmount + totalTaxAmount+otherFeeWithTax).toFixed(2)));
          setresAmount(Number((totalAmount + totalTaxAmount +otherFeeWithTax).toFixed(2)));
          }else if  (props.adjustDueDateReq?.length==1&& props.adjustDueDateReq[0].agreementStatus == "PRELIMINV"&&Number(e.target.value)<=Number(errorValue)) {
            adjustDueDateChange(moment(currentduedate).add(1, "d").format("YYYY-MM-DD"),'Date')
          }else{
            settotalAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
            setresAmount(Number((totalAmount + totalTaxAmount).toFixed(2)));
          }
          // seterrorDate(false)
        }
      }
    }
    setloadSpinner(false);
  };

  /**
   * saveAdjustDueDate to send the response to take payment
   */
  const saveAdjustDueDateRedirect=async()=>{
    if(props.configFlags.commonModuleFlag==1){
      saveAdjustDueDateCommonModule()
    }else{
      saveAdjustDueDate()
    }
  }
  const saveAdjustDueDateCommonModule=async()=>{
    setLoadingSave(true);
    for (let i = 0; i < props.adjustDueDateReq.length; i++) {
      const agreeId = calciRes.filter(
        (x) =>
          String(x.agreementId) ===
          String(props.adjustDueDateReq[i].Agreementid)
      );
      props.adjustDueDateReq[i].dueDateModified=false
      props.adjustDueDateReq[i].isAdjustDueDateValue=agreeId[0].nextDueDate
      console.log("agreeId",agreeId[0].nextDueDate)
      
    }
    console.log("props.adjustDueDateReq",props.adjustDueDateReq)
    
    let commonModulePayload = await calculationModulePayloadFormation( {
      customerId:props.customerId,
      storeNumber:props.storeNumber,
      checkedAgr:props.adjustDueDateReq})
    let commonModuleServiceRes = await calculatePaymentModule(commonModulePayload)
    commonModuleServiceRes=commonModuleServiceRes.data
    setLoadingSave(false);
    for (let i = 0; i < props.adjustDueDateReq.length; i++) {
     
      const agreeId = calciRes.filter(
        (x) =>
          String(x.agreementId) ===
          String(props.adjustDueDateReq[i].Agreementid)
      );
      let commonModuleAgrRes :any =[]
     if(props.adjustDueDateReq[i].Agreementtype!='Club'){
      commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.agreementPaymentDetails.filter(
        (x) =>
          String(x.agreementId) ===
          String(props.adjustDueDateReq[i].Agreementid)
      );
     }else{
      commonModuleAgrRes = commonModuleServiceRes?.paymentDetails[0]?.clubPaymentDetails.filter(
        (x) =>
          String(x.clubMembershipNumber) ===
          String(props.adjustDueDateReq[i].AgreementNumber)
      );
     }
      
      const agreeType = props.adjustDueDateReq.filter(
        (x) => String(x.Agreementid) === String(agreeId[0].agreementId)
      );
      // const nextduedate = moment(agreeId[0].nextDueDate).format('MM/DD/YYYY');
      let nextduedate;
      if (
        agreeId[0].nextDueDate != "SAC" &&
        agreeId[0].nextDueDate != "PIF" &&
        agreeId[0].nextDueDate != "EPO"
      ) {
        nextduedate = moment(agreeId[0].nextDueDate).format("MM/DD/YYYY");
      } else {
        nextduedate = agreeId[0].nextDueDate;
      }
      (props.adjustDueDateReq[i].Nextduedate = ["PIF Rent", "EPO Rent"].includes(commonModuleAgrRes[0].agreementType) &&commonModuleAgrRes[0].isNoteAgreement=='Y'?commonModuleAgrRes[0].nextDueDate==null? ["PIF","SAC"].includes(commonModuleAgrRes[0].isFinalPayment)?commonModuleAgrRes[0].isFinalPayment:["INAGMEPO","INEPO"].includes(commonModuleAgrRes[0].isFinalPayment)?'EPO':["INAGMSAC"].includes(commonModuleAgrRes[0].isFinalPayment)?'SAC':commonModuleAgrRes[0].isFinalPayment:moment(commonModuleAgrRes[0].nextDueDate).format("MM/DD/YYYY"):nextduedate),
      props.adjustDueDateReq[i].isAdjustDueDateValue=undefined
      

        // (props.adjustDueDateReq[i].Amount = Number(
        //   (
        //     Number(agreeId[0].totalAmount) + Number(agreeId[0].totalTax) + Number(agreeId[0].carriedLate) + Number(agreeId[0].carriedLateTax)
        //   ).toFixed(2)
        // )),
        props.adjustDueDateReq[i].Amount = Number(
          (Number(commonModuleAgrRes[0].totalAmount)+Number(commonModuleAgrRes[0].totalTax)).toFixed(2)
        );
        // props.adjustDueDateReq[i].Schedule = props.adjustDueDateReq[i].Schedule,
        // (props.adjustDueDateReq[i].Taxamount = Number(agreeId[0].totalTax) + Number(agreeId[0].carriedLateTax));
      let ldw=commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory == "LDW"||x.itemCategory == "LTLDW")
      let policyAmount =commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='RACTIREPAY')
      let carryLateFee =commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='CLFEEPAY')
      let lateFee = commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='LTRPAYFEE')
      let carryRentUsed = commonModuleAgrRes[0].amountDetails?.filter((x)=>x.itemCategory=='CRPAY')
      
      props.adjustDueDateReq[i].Taxamount = Number(commonModuleAgrRes[0].totalTax)
      props.adjustDueDateReq[i].policyAmount = policyAmount?.length>0?Number(policyAmount[0].amount):0
      // props.adjustDueDateReq[i].editSchedule=selectedSchedule
      // (props.adjustDueDateReq[i].ldwTaxAmount = Number(
      //   agreeId[0].ldwTaxAmount
      // )),
      props.adjustDueDateReq[i].ldwTaxAmount =ldw?.length>0?Number(ldw[0].tax):0
      props.adjustDueDateReq[i].agrLdwAmount =ldw?.length>0?Number(ldw[0].amount):0
      
      // props.adjustDueDateReq[i].policyTaxAmount = Number(
      //   agreeId[0].policyTaxAmount
      // );
      props.adjustDueDateReq[i].policyTaxAmount= policyAmount?.length>0?Number(policyAmount[0].tax):0
      props.adjustDueDateReq[i].carriedLateTax = carryLateFee?.length>0?Number(carryLateFee[0].tax):0
      props.adjustDueDateReq[i].lFeeTaxAmount = lateFee?.length>0?Number(lateFee[0].tax):0
      props.adjustDueDateReq[i].carryRentTaxUsed =carryRentUsed?.length>0?Number(carryRentUsed[0].tax):0
      props.adjustDueDateReq[i].agreementRate = commonModuleAgrRes?.length>0?Number(commonModuleAgrRes[0].scheduleRate):0
      // props.adjustDueDateReq[i].rpayTaxAmount = Number(
      //   agreeId[0].rpayTaxAmount
      // );
      props.adjustDueDateReq[i].rpayTaxAmount = commonModuleAgrRes?.length>0?Number(commonModuleAgrRes[0].totalTax):0
      props.adjustDueDateReq[i].Agreementtype =
        agreeType[0].Agreementtype == "Club"
          ? "Club"
          :["PIF Rent", "EPO Rent"].includes(commonModuleAgrRes[0].agreementType) &&commonModuleAgrRes[0].isNoteAgreement=='Y'?commonModuleAgrRes[0].agreementType: agreeId[0].agreementType;
      if (props.adjustDueDateReq[i].agreementStatus == "PRELIMINV") {
        props.adjustDueDateReq[i].extensionAmount =
          Number(agreeId[0].extensionAmount) > 0
            ? Number(agreeId[0].extensionAmount)
            : 0;
        props.adjustDueDateReq[i].noOfDaysFree =
          Number(agreeId[0].freeDays) > 0 ? Number(agreeId[0].freeDays) : 0;
      }
    }
    let traceObj={
      iD:Number(userTraceData?.length)+1,
      type:"AdjustDueDate"
    }
    setUserTraceData([...userTraceData,traceObj])
    props.response({ resArray: props.adjustDueDateReq, adjustEditflag: 1 });
  }
  const saveAdjustDueDate = async () => {
    setLoadingSave(true);
    // setcalciRes(adjustDueRes)
    let taxInputsData: any = [];

    const clubFilteredData = calciRes.filter((el) => el.agreementType == 'Club');
    const nonClubFilteredData = calciRes.filter((el) => el.agreementType !== 'Club' && el.agreementType !== 'EPO Rent');
    const EPOSACFilteredData = calciRes.filter((el) => el.agreementType == 'EPO Rent');
    const getUrl = window.location.href;
    if(EPOSACFilteredData.length) {
      EPOSACFilteredData.map((el) => {
        taxInputsData.push(
          {
            amount: el.policyAmount,
            taxInputId: `${el.agreementId}`,
            itemCategory: "RACTIREPAY",
          },
          {
            amount: el.agreementRate,
            taxInputId: `${el.agreementId}`,
            itemCategory: "RPAY",
          },
          {
            amount: el.ldwAmount,
            taxInputId: `${el.agreementId}`,
            itemCategory: "LDW",
          },
        );
      });
    }
    if(nonClubFilteredData.length) {
      nonClubFilteredData.map((el) => {
        taxInputsData.push(
          {
            amount: el.policyAmount,
            taxInputId: `${el.agreementId}`,
            itemCategory: "RACTIREPAY",
          },
          {
            amount: el.agreementRate,
            taxInputId: `${el.agreementId}`,
            itemCategory: getUrl.includes('/payment1/customerorder/initial/') ? "INITPAY": "RPAY",
          },
          {
            amount: el.ldwAmount,
            taxInputId: `${el.agreementId}`,
            itemCategory: "LDW",
          },
          {
            amount: el.lateFee,
            taxInputId: `${el.agreementId}`,
            itemCategory: "LTRPAYFEE",
          },
          {
            amount: el.carryRent,
            taxInputId: `${el.agreementId}`,
            itemCategory: "CRPAY",
          },
          {
            amount: el.carriedLate,
            taxInputId: `${el.agreementId}`,
            itemCategory: "CLFEEPAY",
          }
        );
      });
    }
    if(clubFilteredData.length) {
      clubFilteredData.map((el) => {
        taxInputsData.push(
          {
            amount: el.policyAmount,
            taxInputId: `${el.agreementId}`,
            itemCategory: "RACTIREPAY",
          },
          {
            amount: el.agreementRate,
            taxInputId: `${el.agreementId}`,
            itemCategory: "CLUBPAY",
          },
          {
            amount: el.ldwAmount,
            taxInputId: `${el.agreementId}`,
            itemCategory: "LDW",
          },
          {
            amount: el.lateFee,
            taxInputId: `${el.agreementId}`,
            itemCategory: "LTRPAYFEE",
          },
          {
            amount: el.carryRent,
            taxInputId: `${el.agreementId}`,
            itemCategory: "CRPAY",
          },
          {
            amount: el.carriedLate,
            taxInputId: `${el.agreementId}`,
            itemCategory: "CLFEEPAY",
          }
        );
      });
    }

    let customerAdressFilterArray: any = props.customerInformation?.addresses?.filter(
      (x) => {
        return x.addressTypeDesc == "Primary Address";
      }
    );

    let zip5 = "";
    let zip4 = "0000";
    let postalZip=props.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip==null?null:
    props.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip?.replace(/-/g,"")

      let JsonsessionStoreDetails: any =
        window.sessionStorage.getItem("storeDetails");
      console.log(JsonsessionStoreDetails, "sessionStoreDetails1");
      // console.log(atob(JsonsessionStoreDetails),"sessionStoreDetails2")
      let sessionStoreDetails: any;
      try {
        sessionStoreDetails = JSON.parse(atob(JsonsessionStoreDetails));
      } catch (e: any) {
        console.log(e, "not json formate");
      }

      console.log(sessionStoreDetails, "sessionStoreDetails2");
      if (postalZip?.length != 5 || postalZip == undefined || postalZip == null) {
        zip5 = sessionStoreDetails?.zip?.split("-")[0];
        zip4 = sessionStoreDetails?.zip?.split("-")[1];
        if(zip4?.length!=4){
          zip4='0000'
        }
      }else{
        zip5 = props?.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.zip
        if(props?.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.plus4?.length==4){
          zip4 = props?.defaultPaygridvalues?.paymentInfo?.customerInfo[0]?.plus4
        }else{
          zip4='0000'
        }
        
        
      }
      console.log("sessionStoreDetails?.zip",sessionStoreDetails?.zip)


    const formedTaxPayload: any = {
      customerState: props.defaultPaygridvalues.paymentInfo?.customerInfo[0]?.state,
      postalCode:zip5+"-"+zip4,
      customerId: props?.customerInformation?.customerId,
      apiCall: "",
      dateOfBirth: moment(props.defaultPaygridvalues.paymentInfo.customerInfo[0].dob).format("YYYY-MM-DD"),
      taxRateOnly: "N",
      taxExemptAsOfToday: props.taxExemption=="" ? "N":props.taxExemption,
    };

    formedTaxPayload.storeInputs = [
      {
        taxInputs: taxInputsData,
        storeNumber: String(window.sessionStorage.getItem("storeNumber")),
      },
    ];

    const taxEngineResponse = await getTax(formedTaxPayload);
    setLoadingSave(false);
    console.log('58c5', props.adjustDueDateReq);

    for (let i = 0; i < props.adjustDueDateReq.length; i++) {
      debugger;
      let requiredTaxData;
      let taxEngineLDWTax: any = 0;
      let taxEnginePolicyTax = 0;
      let taxEngineRPayTax = 0;
      let taxEngineLateTax = 0;
      let taxEngineCarryRentTax = 0;
      let taxEngineCarriedLateTax = 0;
      let taxEngineClubPayTax = 0;
      let taxEngineInitialPayTax = 0;
      console.log('cvbpgfdocj8r', taxEngineResponse);
      requiredTaxData = taxEngineResponse.data.taxResponse[0].taxOutputs.filter((el) => el.taxOutputId == props.adjustDueDateReq[i].Agreementid);

      const FilterOnlyRpay = requiredTaxData.filter(
        (el) => el.itemCategory == "RPAY"
      );
      const FilterOnlyLDW = requiredTaxData.filter(
        (el) => el.itemCategory == "LDW"
      );
      const FilterOnlyPolicy = requiredTaxData.filter(
        (el) => el.itemCategory == "RACTIREPAY"
      );
      const FilterOnlyLate = requiredTaxData.filter(
        (el) => el.itemCategory == "LTRPAYFEE"
      );
      const FilterOnlyCarryRent = requiredTaxData.filter(
        (el) => el.itemCategory == "CRPAY"
      );
      const FilterOnlyCarriedLate = requiredTaxData.filter(
        (el) => el.itemCategory == "CLFEEPAY"
      );
      const FilterOnlyClubPay = requiredTaxData.filter(
        (el) => el.itemCategory == "CLUBPAY"
      );
      const FilterOnlyInitialPay = requiredTaxData.filter(
        (el) => el.itemCategory == "INITPAY"
      );

      taxEngineLDWTax = FilterOnlyLDW[0].taxAmount;
      taxEnginePolicyTax = FilterOnlyPolicy[0].taxAmount;
      if(FilterOnlyRpay.length) {
        taxEngineRPayTax = FilterOnlyRpay[0].taxAmount;
      }
      if(FilterOnlyLate.length) {
        taxEngineLateTax = FilterOnlyLate[0].taxAmount;
      }
      if(FilterOnlyCarryRent.length) {
        taxEngineCarryRentTax = FilterOnlyCarryRent[0].taxAmount;
      }
      if(FilterOnlyCarriedLate.length) {
        taxEngineCarriedLateTax = FilterOnlyCarriedLate[0].taxAmount;
      }
      if(FilterOnlyClubPay.length) {
        taxEngineClubPayTax = FilterOnlyClubPay[0].taxAmount;
      }
      if(FilterOnlyInitialPay.length) {
        taxEngineInitialPayTax = FilterOnlyInitialPay[0].taxAmount
      }

      console.log(
        "taxvaluesdjh",
        taxEngineLDWTax,
        taxEnginePolicyTax,
        taxEngineRPayTax,
        taxEngineLateTax,
        taxEngineCarryRentTax,
        taxEngineCarriedLateTax
      );

      const agreeId = calciRes.filter(
        (x) =>
          String(x.agreementId) ===
          String(props.adjustDueDateReq[i].Agreementid)
      );
      const agreeType = props.adjustDueDateReq.filter(
        (x) => String(x.Agreementid) === String(agreeId[0].agreementId)
      );
      // const nextduedate = moment(agreeId[0].nextDueDate).format('MM/DD/YYYY');
      let nextduedate;
      if (
        agreeId[0].nextDueDate != "SAC" &&
        agreeId[0].nextDueDate != "PIF" &&
        agreeId[0].nextDueDate != "EPO"
      ) {
        nextduedate = moment(agreeId[0].nextDueDate).format("MM/DD/YYYY");
      } else {
        nextduedate = agreeId[0].nextDueDate;
      }
      let otherFeeWithTax = Number(props.adjustDueDateReq[i].processingFee )+
     Number( props.adjustDueDateReq[i].deliveryAmount )+
     Number( props.adjustDueDateReq[i].mattressFee) +
     Number( props.adjustDueDateReq[i].processingFeeTax )+
     Number( props.adjustDueDateReq[i].deliveryAmountTax )+
     Number( props.adjustDueDateReq[i].mattressFeeTax);
     let otherFeeTax = 
     Number( props.adjustDueDateReq[i].processingFeeTax )+
     Number( props.adjustDueDateReq[i].deliveryAmountTax )+
     Number( props.adjustDueDateReq[i].mattressFeeTax);

     otherFeeWithTax =Number(otherFeeWithTax)>0?Number(otherFeeWithTax):0;
     otherFeeTax=Number(otherFeeTax)>0?Number(otherFeeTax):0;
      (props.adjustDueDateReq[i].Nextduedate = nextduedate),
        // (props.adjustDueDateReq[i].Amount = Number(
        //   (
        //     Number(agreeId[0].totalAmount) + Number(agreeId[0].totalTax) + Number(agreeId[0].carriedLate) + Number(agreeId[0].carriedLateTax)
        //   ).toFixed(2)
        // )),
        
        
        
        
        

     
        (props.adjustDueDateReq[i].Amount = Number(
          (
            Number(agreeId[0].totalAmount) +
            Number(otherFeeWithTax)+
            Number(taxEngineLDWTax) +
            Number(agreeId[0].carriedLate) +
            Number(taxEnginePolicyTax) +
            Number(taxEngineLateTax) + 
            Number(taxEngineCarryRentTax) +
            Number(taxEngineCarriedLateTax) + 
            (agreeId[0].agreementType == 'Club' ? 
            Number(taxEngineClubPayTax) : Number(taxEngineRPayTax)) +
            Number(taxEngineInitialPayTax)
          ).toFixed(2)
        )),
        // props.adjustDueDateReq[i].Schedule = props.adjustDueDateReq[i].Schedule,
        // (props.adjustDueDateReq[i].Taxamount = Number(agreeId[0].totalTax) + Number(agreeId[0].carriedLateTax));
        (props.adjustDueDateReq[i].Taxamount = Number(taxEngineLDWTax)+Number(otherFeeTax) + Number(taxEnginePolicyTax) + Number(taxEngineInitialPayTax)
        + Number(taxEngineLateTax) + Number(taxEngineCarryRentTax) + Number(taxEngineCarriedLateTax) + (agreeId[0].agreementType == 'Club' ? 
        Number(taxEngineClubPayTax) : Number(taxEngineRPayTax)));
      props.adjustDueDateReq[i].policyAmount = Number(agreeId[0].policyAmount);
      // props.adjustDueDateReq[i].editSchedule=selectedSchedule
      // (props.adjustDueDateReq[i].ldwTaxAmount = Number(
      //   agreeId[0].ldwTaxAmount
      // )),
      (props.adjustDueDateReq[i].ldwTaxAmount = Number(
        taxEngineLDWTax
      )),
        (props.adjustDueDateReq[i].agrLdwAmount = Number(agreeId[0].ldwAmount));
      // props.adjustDueDateReq[i].policyTaxAmount = Number(
      //   agreeId[0].policyTaxAmount
      // );
      props.adjustDueDateReq[i].policyTaxAmount = Number(
        taxEnginePolicyTax
      );
      props.adjustDueDateReq[i].carriedLateTax = Number(
        taxEngineCarriedLateTax
      );
      props.adjustDueDateReq[i].lFeeTaxAmount = Number(
        taxEngineLateTax
      );
      props.adjustDueDateReq[i].carryRentTaxUsed = Number(
        taxEngineCarryRentTax
      );
      props.adjustDueDateReq[i].agreementRate = Number(
        agreeId[0].agreementRate
      );
      // props.adjustDueDateReq[i].rpayTaxAmount = Number(
      //   agreeId[0].rpayTaxAmount
      // );
      props.adjustDueDateReq[i].rpayTaxAmount = agreeId[0].agreementType == 'Club' ?  0 : Number(
        taxEngineRPayTax
      ) + Number(taxEngineInitialPayTax);
      props.adjustDueDateReq[i].Agreementtype =
        agreeType[0].Agreementtype == "Club"
          ? "Club"
          : agreeId[0].agreementType;
      if (props.adjustDueDateReq[i].agreementStatus == "PRELIMINV") {
        props.adjustDueDateReq[i].extensionAmount =
          Number(agreeId[0].extensionAmount) > 0
            ? Number(agreeId[0].extensionAmount)
            : 0;
        props.adjustDueDateReq[i].noOfDaysFree =
          Number(agreeId[0].freeDays) > 0 ? Number(agreeId[0].freeDays) : 0;
      }
    }
    props.response({ resArray: props.adjustDueDateReq, adjustEditflag: 1 });
    // props.setIsInitialLoadUpdate();
  };
  /**
   * adjustDueDatePopUP returns the UI
   * @returns
   */

  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };
  const adjustDueDatePopUP = () => {
    return (
      <Grid container spacing={2} className={classes.px3}>
        <Grid item className={`${classes.w100} ${classes.mt2}`}>
          <Grid
            className={`${classes.w100} ${classes.mb3}`}
            style={{ display: "flex" }}
          >
            <Grid className={`${classes.ms2} ${classes.w100}`}>
              <RACDatePicker
                value={currentduedate}
                label="Current Due Date"
                name="currentDueDate"
                disabled
              />
            </Grid>
            <Grid className={`${classes.ms2} ${classes.w100}`}>
              <RACDatePicker
                value={nextDueDate}
                label="Next Due Date"
                name="nextDueDate"
                disabled={loadSpinner}
                onChange={(e: any) => {
                  const minDate = moment(currentduedate)
                    .add(1, "d")
                    .format("YYYY-MM-DD");
                  if (
                    moment(e, "YYYY-MM-DD").isBefore(minDate)
                  ) {
                    setIsInvalidDDSelected(true);
                  } else {
                    setIsInvalidDDSelected(false);
                  }
                  adjustDueDateChange(e, "Date")
                }}
                inputProps={{
                  min: moment(currentduedate).add(1, "d").format("YYYY-MM-DD"),
                }}
                {...(isInvalidDDSelected && {
                  errorMessage: "Please choose a valid due date",
                })}
              />
            </Grid>
            {loadSpinner === true ? (
              <CircularProgress
                className={classes.alertLoader}
              ></CircularProgress>
            ) : null}
          </Grid>
          <Grid
            item
            className={`${classes.w50} ${classes.mt2} ${classes.floatLeft}`}
          >
            <label
              htmlFor="a11y_takehome"
              className={`${classes.formLabel} ${classes.ms2}  ${classes.mb1}${classes.floatLeft}`}
            >
              Total Amount
            </label>
            <Grid
              className={`${classes.w100} ${classes.inputgroup} ${classes.mb3}`}
            >
              <Grid className={`${classes.ms2} ${classes.w100}`}>
                <RACTextbox
                  isCurrency={true}
                  className={`${classes.custDigit} ${classes.cashtxtbox} ${classes.adjustTotalAmountField}`}
                  dollarTextClassName={`${classes.inputgrouptext} ${classes.cashdollor}`}
                  value={String(totalAmount)}
                  Onblur={(e) => adjustDueDateChange(e, "Amouont")}
                  OnChange={(e: any) => amountOnChange(e)}
                  name="adjustTotalAmount"
                  digitFormat={"currency"}
                  maxlength={10}
                  // label="Total Amount"
                  disabled={loadSpinner}
                  errorMessage={
                    props?.adjustDueDateReq[0]?.agreementStatus != "PRELIMINV"? errorAmount
                      ? `Amount Should not be less than ${Number(
                          errorValue
                        ).toFixed(2)} `
                      : "":""
                  }
                />
              </Grid>
              {loadSpinner === true ? (
                <CircularProgress
                  className={classes.alertLoader}
                ></CircularProgress>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.w100} ${classes.inputgroup} ${classes.mb3}`}
        >
          {finalAdjustedArray.length > 0 ? (
            <Typography
              component={"label"}
              id={"epoAmountLbl"}
              className={`${classes.mandatoryfield} ${classes.susErrorLbl}`}
            >
              {finalAdjustedArray}
            </Typography>
          ) : null}
        </Grid>

        <Grid
          className={`${classes.textright} ${classes.modalfooter} ${classes.floatRight} ${classes.gridwidth}`}
        >
          {props?.adjustDueDateReq?.length==1&& props?.adjustDueDateReq[0]?.agreementStatus == "PRELIMINV"?"":
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="outlined"
            name="clearCancel"
            data-bs-toggle="modal"
            style={{
              backgroundColor: "white",
              color: "#2C3333",
            }}
            onClick={() => {
              props.isClosed(false);
            }}
          >
            Cancel
          </RACButton>}
          <RACButton
            className={classes.ms2}
            color="primary"
            variant="contained"
            name="De_ActivateClubbtn"
            data-bs-toggle="modal"
            onClick={() => saveAdjustDueDateRedirect()}
            disabled={errorAmount || loadingSave || isInvalidDDSelected}
            loading={loadingSave}
          >
            Save
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <RACModalCard
      isOpen={props.isOpen}
      maxWidth="xs"
      title="Adjust Due Date"
      //onClose={() => props.isClosed(false)}
      //closeIcon={true}
      borderRadius="20px"
      /* eslint-disable react/no-children-prop */
      // eslint-disable-next-line sonarjs/no-use-of-empty-return-value
      children={adjustDueDatePopUP()}
    />
  );
};

export default AdjustDueDatePopup;
