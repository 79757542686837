/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable sonarjs/no-duplicate-string */

import { makeStyles, RACCOLOR } from '@rentacenter/racstrap';
export const agreementGlobalStyles = () => {
  const useClasses = makeStyles((theme) => ({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '10px',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(189, 189, 196)',
        borderRadius: '20px',
        border: '3px solid rgb(206, 204, 201)',
      },
      '*::-webkit-scrollbar-track': {
        background: 'rgb(245, 243, 239)',
      },
    },
    title: {
      color: '#000',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(10),
    },
    subTitle: {
      color: '#212529',
      fontFamily: 'OpenSans-bold',
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.typography.pxToRem(10),
    },
    card: {
      borderRadius: theme.typography.pxToRem(16),
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
      width: '100%',
    },
    widthAuto: {
      width: 'auto',
    },
    w50: {
      width: '50%',
    },
    w100: {
      width: '100%',
    },
    textWhite: {
      color: `${RACCOLOR.WHITE}`,
    },
    textRed: {
      color: `${RACCOLOR.MVS_RED}`,
    },
    textBlue: {
      color: `${RACCOLOR.NORMAL_BLUE}`,
    },
    racLightBlue: {
      backgroundColor: `${RACCOLOR.LAVENDER_MIST}`,
    },
    racBlue: {
      backgroundColor: `${RACCOLOR.NORMAL_BLUE}`,
    },
    racTextGreen: {
      color: '#00985e',
    },
    flexCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    row: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: theme.typography.pxToRem(14),
      marginRight: theme.typography.pxToRem(14),
      marginLeft: theme.typography.pxToRem(14),
    },
    formControl: {
      display: 'block',
      width: '100%',
      padding: '0.375rem 0.75rem',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.5,
      color: '#212529',
      backgroundColor: `${RACCOLOR.WHITE}`,
      border: '1px solid #ced4da',
      appearance: 'none',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '0.25rem',
      borderBottomRightRadius: '0.25rem',
    },

    floatLeft: {
      float: 'left',
    },
    floatRight: {
      float: 'right',
    },

    m0: {
      margin: 0,
    },

    m1: {
      margin: theme.typography.pxToRem(4),
    },

    m2: {
      margin: theme.typography.pxToRem(8),
    },

    m3: {
      margin: theme.typography.pxToRem(16),
    },

    m4: {
      margin: theme.typography.pxToRem(24),
    },

    m5: {
      margin: theme.typography.pxToRem(48),
    },

    mAuto: {
      margin: 'auto',
    },

    mx0: {
      marginRight: 0,
      marginLeft: 0,
    },

    mx1: {
      marginRight: theme.typography.pxToRem(4),
      marginLeft: theme.typography.pxToRem(4),
    },
    mx2: {
      marginRight: theme.typography.pxToRem(8),
      marginLeft: theme.typography.pxToRem(8),
    },

    mx3: {
      marginRight: theme.typography.pxToRem(16),
      marginLeft: theme.typography.pxToRem(16),
    },
    mx4: {
      marginRight: theme.typography.pxToRem(24),
      marginLeft: theme.typography.pxToRem(24),
    },

    mx5: {
      marginRight: theme.typography.pxToRem(48),
      marginLeft: theme.typography.pxToRem(48),
    },

    mxAuto: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },

    my0: {
      marginTop: 0,
      marginBottom: 0,
    },

    my1: {
      marginTop: theme.typography.pxToRem(4),
      marginBottom: theme.typography.pxToRem(4),
    },

    my2: {
      marginTop: theme.typography.pxToRem(8),
      marginBottom: theme.typography.pxToRem(8),
    },

    my3: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },

    my4: {
      marginTop: theme.typography.pxToRem(24),
      marginBottom: theme.typography.pxToRem(24),
    },

    my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
    },

    myAuto: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },

    mt0: {
      marginTop: 0,
    },

    mt1: {
      marginTop: theme.typography.pxToRem(4),
    },

    mt2: {
      marginTop: theme.typography.pxToRem(8),
    },

    mt3: {
      marginTop: theme.typography.pxToRem(16),
    },

    mt4: {
      marginTop: theme.typography.pxToRem(24),
    },

    mt5: {
      marginTop: theme.typography.pxToRem(48),
    },

    mtAuto: {
      marginTop: 'auto',
    },

    me0: {
      marginRight: 0,
    },

    me1: {
      marginRight: theme.typography.pxToRem(4),
    },
    me2: {
      marginRight: theme.typography.pxToRem(8),
    },

    me3: {
      marginRight: theme.typography.pxToRem(16),
    },

    me4: {
      marginRight: theme.typography.pxToRem(24),
    },

    me5: {
      marginRight: theme.typography.pxToRem(48),
    },

    meAuto: {
      marginRight: 'auto',
    },

    mb0: {
      marginBottom: 0,
    },

    mb1: {
      marginBottom: theme.typography.pxToRem(4),
    },

    mb2: {
      marginBottom: theme.typography.pxToRem(8),
    },
    mb3: {
      marginBottom: theme.typography.pxToRem(16),
    },

    mb4: {
      marginBottom: theme.typography.pxToRem(24),
    },

    mb5: {
      marginBottom: theme.typography.pxToRem(48),
    },
    mb6: {
      marginBottom: theme.typography.pxToRem(96),
    },
    mb32: {
      marginBottom: theme.typography.pxToRem(32),
    },
    mbAuto: {
      marginBottom: 'auto',
    },

    ms0: {
      marginLeft: 0,
    },

    ms1: {
      marginLeft: theme.typography.pxToRem(4),
    },

    ms2: {
      marginLeft: theme.typography.pxToRem(8),
    },

    ms3: {
      marginLeft: theme.typography.pxToRem(16),
    },

    ms4: {
      marginLeft: theme.typography.pxToRem(24),
    },

    ms5: {
      marginLeft: theme.typography.pxToRem(48),
    },

    msAuto: {
      marginLeft: 'auto',
    },

    p0: {
      padding: 0,
    },

    p1: {
      padding: theme.typography.pxToRem(4),
    },

    p2: {
      padding: theme.typography.pxToRem(8),
    },

    p3: {
      padding: theme.typography.pxToRem(16),
    },

    p4: {
      padding: theme.typography.pxToRem(24),
    },

    p5: {
      padding: theme.typography.pxToRem(48),
    },
    p32: {
      padding: theme.typography.pxToRem(32),
    },

    px0: {
      paddingRight: 0,
      paddingLeft: 0,
    },

    px1: {
      paddingRight: theme.typography.pxToRem(4),
      paddingLeft: theme.typography.pxToRem(4),
    },

    px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
    },

    px3: {
      paddingRight: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(16),
    },

    px4: {
      paddingRight: theme.typography.pxToRem(24),
      paddingLeft: theme.typography.pxToRem(24),
    },

    px5: {
      paddingRight: theme.typography.pxToRem(48),
      paddingLeft: theme.typography.pxToRem(48),
    },

    py0: {
      paddingTop: 0,
      paddingBottom: 0,
    },

    py1: {
      paddingTop: theme.typography.pxToRem(4),
      paddingBottom: theme.typography.pxToRem(4),
    },

    py2: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },

    py3: {
      paddingTop: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },

    py4: {
      paddingTop: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(24),
    },

    py5: {
      paddingTop: theme.typography.pxToRem(48),
      paddingBottom: theme.typography.pxToRem(48),
    },

    pt0: {
      paddingTop: 0,
    },

    pt1: {
      paddingTop: theme.typography.pxToRem(4),
    },

    pt2: {
      paddingTop: theme.typography.pxToRem(8),
    },

    pt3: {
      paddingTop: theme.typography.pxToRem(16),
    },

    pt4: {
      paddingTop: theme.typography.pxToRem(24),
    },

    pt5: {
      paddingTop: theme.typography.pxToRem(48),
    },

    pe0: {
      paddingRight: 0,
    },

    pe1: {
      paddingRight: theme.typography.pxToRem(4),
    },

    pe2: {
      paddingRight: theme.typography.pxToRem(8),
    },

    pe3: {
      paddingRight: theme.typography.pxToRem(16),
    },

    pe4: {
      paddingRight: theme.typography.pxToRem(24),
    },

    pe5: {
      paddingRight: theme.typography.pxToRem(48),
    },

    pb0: {
      paddingBottom: 0,
    },

    pb1: {
      paddingBottom: theme.typography.pxToRem(4),
    },

    pb2: {
      paddingBottom: theme.typography.pxToRem(8),
    },

    pb3: {
      paddingBottom: theme.typography.pxToRem(16),
    },

    pb4: {
      paddingBottom: theme.typography.pxToRem(24),
    },

    pb5: {
      paddingBottom: theme.typography.pxToRem(48),
    },

    ps0: {
      paddingLeft: 0,
    },

    ps1: {
      paddingLeft: theme.typography.pxToRem(4),
    },

    ps2: {
      paddingLeft: theme.typography.pxToRem(8),
    },

    ps3: {
      paddingLeft: theme.typography.pxToRem(16),
    },

    ps4: {
      paddingLeft: theme.typography.pxToRem(24),
    },

    ps5: {
      paddingLeft: theme.typography.pxToRem(48),
    },

    font11: {
      fontSize: theme.typography.pxToRem(11),
    },

    font12: {
      fontSize: theme.typography.pxToRem(12),
    },

    font13: {
      fontSize: theme.typography.pxToRem(13),
    },

    font14: {
      fontSize: theme.typography.pxToRem(14),
    },

    font15: {
      fontSize: theme.typography.pxToRem(15),
    },

    font16: {
      fontSize: theme.typography.pxToRem(16),
    },

    font17: {
      fontSize: theme.typography.pxToRem(17),
    },

    font18: {
      fontSize: theme.typography.pxToRem(18),
    },
    font24: {
      fontSize: theme.typography.pxToRem(24),
    },
    textLeft: {
      textAlign: 'left',
    },

    textCenter: {
      textAlign: 'center',
    },

    textRight: {
      textAlign: 'right',
    },
    /* Text Alignment */

    semiBold: {
      fontFamily: 'OpenSans-semibold',
    },

    bold: {
      fontFamily: 'OpenSans-Bold',
    },

    regular: {
      fontFamily: 'OpenSans-regular',
    },

    formLabel: {
      marginBottom: '0.2rem',
      color: '#000',
      fontFamily: 'OpenSans-semibold',
      fontSize: theme.typography.pxToRem(14),
    },
    racpadLink: {
      color: '#2179FE',
      fontSize: theme.typography.pxToRem(14),
      textDecoration: 'none',
      cursor: 'pointer',
      fontFamily: 'OpenSans-semibold',
    },
    masterLoader: {
      position: 'fixed',
      backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      opacity: 0.6,
      zIndex: 1050,
      textAlign: 'center',
      margin: 0,
    },
    Loader: {
      display: 'block',
      position: 'fixed',
      zIndex: 1051,
      top: '50%',
      right: '50%',
    },
    GridLoader: {
      textAlign: 'center',
      marginBottom: theme.typography.pxToRem(20),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
      margin: '30px 0px',
    },
    fixedBottom: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1030,
      backgroundColor: `${RACCOLOR.WHITE}`,
      boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
      padding: '1rem',
    },
    Norecords: {
      textAlign: 'center',
      marginTop: theme.typography.pxToRem(30),
      marginBottom: theme.typography.pxToRem(30),
      fontSize: theme.typography.pxToRem(16),
      color: `${RACCOLOR.RICH_BLACK}`,
    },
    mandatoryfield: {
      color: `${RACCOLOR.CRUEL_RUBY}`,
    },
    popupFooter: {
      display: 'flex',
      flexWrap: 'wrap',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0.75rem',
    },
    racGrid: {
      '& th': {
        fontFamily: 'OpenSans-bold',
      },
      '& td': {
        fontFamily: 'OpenSans-semibold',
      },
    },
    iframeClass: {
      height: '500px',
      width: '100%',
    },
    alertIcon: {
      paddingRight: '10px',
    },
    agrVoidAgreementPopupBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    justifyCenter: {
      justifyContent: 'center',
    },
    agrDeliveryReceiptText: {
      fontFamily: 'OpenSans-semibold',
      fontSize: '17px',
    },
    agrPrintDoc: {
      color: '#2179fe',
    },
    popupText: {
      fontFamily: 'OpenSans-semibold',
      fontSize: '17px',
    },
    agrInfoPanel: {
      display: 'block',
      fontSize: '13px',
      color: '#5b6d83',
      fontFamily: 'OpenSans-semibold',
      lineHeight: 'unset',
    },
    agrTableBody: {
      fontSize: '13px',
      color: '#5b6d83',
      fontFamily: 'OpenSans-semibold',
    },
    agrInfoPanelID: {
      display: 'block',
      padding: '0.5rem 1rem',
      textDecoration: 'none',
      fontSize: '12px',
      marginRight: '5px',
    },
    hide: {
      display: 'none',
    },

    payScheduleHighlight: {
      backgroundColor: `#2179FE`,
    },
    paySchedulePeriodHighlight: {
      color: `${RACCOLOR.WHITE}`,
      fontSize: '16px',
      textAlign: 'center',
    },
    paySchedulePeriodNotHighlight: {
      textAlign: 'center',
      fontSize: '16px',
      color: '#000',
    },
    payScheduleRateHighlight: {
      color: `${RACCOLOR.WHITE} !important`,
    },
    payScheduleTermHighlight: {
      color: `${RACCOLOR.WHITE}`,
    },
    payScheduleValueHighlight: {
      color: `${RACCOLOR.WHITE}`,
    },
    payAmount: {
      fontFamily: 'OpenSans-bold',
      fontSize: '22px',
      textAlign: 'right',
      marginRight: '30px',
      color: '#000',
    },
    termAlign: {
      textAlign: 'right',
      marginRight: '30px',
      marginTop: '5px',
    },
    paymentTerm: {
      fontFamily: 'OpenSans-bold',
      fontSize: '16px',
      marginLeft: '3px',
    },
    racViewAgreementTab: {
      backgroundColor: `${RACCOLOR.WHITE}`,
      // paddingTop: theme.typography.pxToRem(14),
      // paddingBottom: theme.typography.pxToRem(14),
    },
    racViewAgreementTabPanel: {
      display: 'inline-flex',
    },

    racTabNavLink: {
      textDecoration: 'none',
      fontSize: '15px',
      color: '#000',
      borderBottom: '3px solid transparent',
    },
    racTabNavLinkActive: {
      borderColor: `${RACCOLOR.NORMAL_BLUE}`,
    },
    agreementContainerSpacing: {
      paddingLeft: '10px',
      paddingRight: '10px',
      marginBottom: '120px',
    },
    agreementPOContainerSpacing: {
      marginBottom: '120px',
      padding: '8px',
    },
    fontHeading: {
      fontSize: theme.typography.pxToRem(15),
      fontFamily: 'OpenSans-bold',
    },
    fontBold: {
      fontFamily: 'OpenSans-bold',
    },
    fixedActionBtn: {
      position: 'fixed',
      bottom: '27px',
    },
    vehicleFixedContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    datePickerMargin: {
      marginTop: '0px',
      color: '#000',
    },
    popupTitle: {
      fontSize: theme.typography.pxToRem(20),
    },
    customModal: {
      '& div': {
        '& div': {
          maxWidth: '1200px',
        },
      },
    },
    textDecoration: {
      textDecoration: 'underline !important',
    },
    racTotalBlue: {
      backgroundColor: '#EFF4FF',
    },
    searchcustomer: {
      fontSize: '18px',
      color: '#000',
    },
    popuptitle: {
      fontSize: '20px !important',
      color: '#000',
    },
    '& body': {
      backgroundColor: '#ECF0F3',
    },
    racpadLinkCustomer: {
      fontFamily: 'OpenSans-bold',
      fontSize: '14px',
      color: '#2179FE',
      textDecoration: 'none',
    },
    tabUI: {
      '&:before': {
        left: '-5px',
        width: '10px',
        bottom: '-5px',
        height: '5px',
        content: "''",
        position: 'absolute',
        background: '#2468ff',
        borderTopLeftRadius: '5px',
      },
      '&:after': {
        right: '-5px',
        width: '10px',
        bottom: '-5px',
        height: '5px',
        content: "''",
        position: 'absolute',
        background: '#2468ff',
        borderTopRightRadius: '5px',
      },
    },
    containerstyle: {
      float: 'left',
      width: '100%',
    },
    formTextArea: {
      width: '100%',

      border: '0.0625rem solid #c4c4c4',

      borderRadius: '0.3125rem',

      resize: 'none',

      outline: 'none',

      minHeight: '80px',

      '&:focus': {
        boxShadow: '0 0 0 0.25rem rgb(0 123 255 / 25%)',

        borderColor: '#80bdff',
      },
    },
    width100: {
      width: '100%',
    },
    parentclass: {
      float: 'left',
      width: '100%',
    },

    flexratio: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    breadcrumb: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      marginBottom: theme.typography.pxToRem(10),
      listStyle: 'none',
    },
    breadcrumbItemActive: {
      width: 'auto',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
      color: ' #8E8E8E',
      paddingLeft: '15px',
      paddingRight: 0,
    },
    breadCrumbMarg: {
      marginLeft: '20px',
    },
    breadcrumbItem: {
      width: 'auto',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
    },
    breadcrumbArrow: {
      '&:before': {
        content: '""',
        width: 0,
        height: 0,
        borderTop: '5px solid transparent',
        borderLeft: '7px solid #707070',
        borderBottom: '5px solid transparent',
        marginTop: 0,
        float: 'left',
        paddingRight: '13px',
        color: '#6c757d',
        paddingLeft: 0,
      },
    },
    agrInfoPanelviewagr: {
      fontSize: '13px',
      color: '#5b6d83',
      fontFamily: 'OpenSans-semibold',
      lineHeight: 'unset',
      float: 'left',
    },
    overall: {
      float: 'left',
    },
    over1: {
      float: 'left',
    },
    check: {
      width: `100% !important`,
      float: 'left',
    },
    breadcrumbsbgcolor: {
      backgroundColor: '#ECF0F3',
    },
    fontstyle: {
      color: '#2179FE',
    },
    //newstyle for customerinfo
    mt15: {
      marginTop: '15%',
    },
    wd70: {
      width: '70%',
    },
    greyFont: {
      color: ' #8E8E8E',
    },
    width20: {
      width: '20%',
    },
    width10: {
      width: '10%',
    },
    justifyContentCenter: {
      justifyContent: 'center',
    },
    p6: {
      padding: '6px !important',
    },
  }));
  const classes = useClasses();

  return classes;
};
