/* eslint-disable prettier/prettier */
import { AxiosResponse } from 'axios';
import { APIError } from '../types/types';
import { AccountManagementURLParam, CONTENT_TYPE } from '../constants/constants';

export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getContentType = (fileName: string) => {
  let result;
  if (fileName && typeof fileName === 'string') {
    const splits = fileName.split('.');
    const extension = splits[splits.length - 1] as string;
    result = CONTENT_TYPE[extension];
  }
  return result || CONTENT_TYPE.pdf;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};

export const isLandedFromAM = (pathName: string) =>
  pathName.includes(AccountManagementURLParam);

export const AppendEllipse = (str: any, max: number, add: any) => {
  add = add || '...';
  return typeof str === 'string' && str.length > max
    ? str.substring(0, max) + add
    : str;
}; // PC - 02 Here all the common methods are defined and exported to thr components.

export const selectAppender = (totalValue: any) => {
  return [
    { description: 'Select reason code', referenceCode: 'SELECT' },
  ].concat(totalValue);
}; // This method will be appending the select option in the reverse reason drop down.

export const b64toBlob = (
  b64Data: any,
  contentType = 'application/pdf',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}; // This method will be converting base 64 to blob URL.

export const isStringWithDigits = (n: number, x: unknown) => typeof x === 'string' && (new RegExp(`^\\d{${n}}$`)).test(x);
